
import { ElForm } from "element-plus"
import { defineComponent, ref, watch } from "vue"
import { staticSchoolTypeList } from "@/core/enums/enums"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { SelectModel } from "@/core/models/SelectModel"
import { StudentGeneralDto } from "@/core/models/StudentGeneralDto"
import ApiService from "@/core/services/ApiService"

export default defineComponent({
  name: "student-general",
  inheritAttrs: false,
  components: {},
  props: ["studentId"],
  setup(props) {
    const studentGeneralData = ref<StudentGeneralDto>({} as StudentGeneralDto)

    const cityList = ref<SelectModel[]>([])
    const districtList = ref<SelectModel[]>([])
    const schoolList = ref<SelectModel[]>([])

    type FormInstance = InstanceType<typeof ElForm>

    const studentDetailFormRef = ref<FormInstance>()

    watch(
      () => props.studentId,
      async newValue => {
        if (newValue) {
          await getStudent(newValue)
          await getCityList()
        }
      }
    )

    const getStudent = async studentId => {
      const { data } = await ApiService.get("student/" + studentId + "/detail")
      studentGeneralData.value = { ...data }
      studentGeneralData.value.schoolType = data.schoolType == 0 ? undefined : data.schoolType

      if (studentGeneralData.value.cityId) {
        await getDistrictList(studentGeneralData.value.cityId)
        await getSchoolList(
          studentGeneralData.value.districtId,
          studentGeneralData.value.schoolType
        )
      }
    }

    const submitForm = async formEl => {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          await saveStudentDetail()
        }
      })
    }
    const saveStudentDetail = async () => {
      const data = {
        schoolId: studentGeneralData.value.schoolId,
        schoolNumber: studentGeneralData.value.schoolNumber,
        id: props.studentId,
      }
      await ApiService.post("student/detail/edit", data)
      await getStudent(props.studentId)
    }

    const getCityList = async () => {
      const { data } = await ApiService.get("location/cities")
      cityList.value = data
    }

    const getDistrictList = async cityId => {
      const { data } = await ApiService.get("location/cities/" + cityId + "/districts")
      districtList.value = data
    }

    const getSchoolList = async (districtId, schoolType) => {
      if (!districtId || !schoolType) {
        schoolList.value = []
        return
      }
      const query = {
        params: {
          schoolType: schoolType,
          districtId: districtId,
        },
      }
      const { data } = await ApiService.query("school", query)
      schoolList.value = data
    }

    const onSchoolTypeChange = async () => {
      schoolList.value = []
      studentGeneralData.value.schoolId = undefined
      await getSchoolList(studentGeneralData.value.districtId, studentGeneralData.value.schoolType)
    }
    const onDistrictChange = async () => {
      schoolList.value = []
      studentGeneralData.value.schoolId = undefined
      await getSchoolList(studentGeneralData.value.districtId, studentGeneralData.value.schoolType)
    }
    const onCityChange = async () => {
      schoolList.value = []
      districtList.value = []

      studentGeneralData.value.schoolId = undefined
      studentGeneralData.value.districtId = undefined
      await getDistrictList(studentGeneralData.value.cityId)
    }

    const rules = ref({
      schoolNumber: getRule(RuleTypes.NUMBER, "Okul Numarası"),
    })

    return {
      studentDetailFormRef,
      rules,

      studentGeneralData,

      cityList,
      districtList,
      schoolList,
      staticSchoolTypeList,

      onSchoolTypeChange,
      onCityChange,
      onDistrictChange,

      getDistrictList,
      getSchoolList,
      submitForm,
    }
  },
})
