import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    data: _ctx.studentNoteList,
    style: {"width":"100%","font-size":"13px"},
    size: "small",
    "table-layout": "auto"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        property: "fullName",
        width: "140"
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("b", null, _toDisplayString(scope.row.createdUser.firstName.toLocaleUpperCase() +
          " " +
          scope.row.createdUser.lastName.toLocaleUpperCase() +
          ":"), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        property: "level",
        width: "100"
      }, {
        default: _withCtx((scope) => [
          _createVNode(_component_el_tag, {
            type: _ctx.levelClass(scope.row.level),
            "disable-transitions": ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.staticLevel(scope.row.level)), 1)
            ]),
            _: 2
          }, 1032, ["type"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, { property: "description" }),
      _createVNode(_component_el_table_column, {
        property: "createdDate",
        width: "150"
      }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(_ctx.dateForm(_ctx.DateFormatTypes.TR_LONG, scope.row.createdDate)), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data"]))
}