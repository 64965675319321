import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    model: _ctx.studentGeneralData,
    rules: _ctx.rules,
    ref: "studentDetailFormRef",
    class: "demo-form-inline"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_row, { gutter: 20 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 4 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "Okul No",
                prop: "schoolNumber"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    controls: false,
                    min: 0,
                    type: "number",
                    placeholder: "Okul No Giriniz",
                    class: "w-100",
                    modelValue: _ctx.studentGeneralData.schoolNumber,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.studentGeneralData.schoolNumber) = $event)),
                    modelModifiers: { number: true }
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 5 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "Okul Tipi",
                prop: "schoolType"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    class: "w-100",
                    onChange: _ctx.onSchoolTypeChange,
                    modelValue: _ctx.studentGeneralData.schoolType,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.studentGeneralData.schoolType) = $event)),
                    filterable: ""
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.staticSchoolTypeList, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.id,
                          label: item.name,
                          value: item.id
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["onChange", "modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 5 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "İl",
                prop: "cityId"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    class: "w-100",
                    onChange: _ctx.onCityChange,
                    modelValue: _ctx.studentGeneralData.cityId,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.studentGeneralData.cityId) = $event)),
                    filterable: ""
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cityList, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.id,
                          label: item.name,
                          value: item.id
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["onChange", "modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 5 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "İlçe",
                prop: "districtId"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    class: "w-100",
                    onChange: _ctx.onDistrictChange,
                    modelValue: _ctx.studentGeneralData.districtId,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.studentGeneralData.districtId) = $event)),
                    filterable: ""
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.districtList, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.id,
                          label: item.name,
                          value: item.id
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["onChange", "modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 5 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, { label: "Okul" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    placeholder: "Okul Seçiniz",
                    modelValue: _ctx.studentGeneralData.schoolId,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.studentGeneralData.schoolId) = $event)),
                    style: {"width":"100%"}
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.schoolList, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.id,
                          label: item.name,
                          value: item.id
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_row, { justify: "end" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.submitForm(_ctx.studentDetailFormRef)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Kaydet")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}