
import { defineComponent } from "vue"
import { staticNoteLevelList, NoteLevelEnum } from "@/core/enums/enums"
import { dateForm, DateFormatTypes } from "@/core/helpers/dateformat"
export default defineComponent({
  name: "student-notes",
  inheritAttrs: false,
  props: ["studentNoteList"],
  setup() {
    const levelClass = (level: NoteLevelEnum): string => {
      switch (level) {
        case NoteLevelEnum.Info:
          return "info"
        case NoteLevelEnum.Warning:
          return "warning"
        case NoteLevelEnum.Error:
          return "error"
        case NoteLevelEnum.System:
          return "system"
        default:
          return "info"
      }
    }

    const staticLevel = (level: number): string => {
      const levelObject = staticNoteLevelList.find(l => l.id === level)
      return levelObject ? levelObject.name : ""
    }

    return {
      dateForm,
      DateFormatTypes,
      levelClass,
      staticLevel,
    }
  },
})
