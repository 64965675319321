
import ApiService from "@/core/services/ApiService"
import { computed, defineComponent, onMounted, ref, watch } from "vue"
import {
  ArrowRight,
  ArrowLeft,
  Minus,
  Edit,
  CircleCheckFilled,
  WarnTriangleFilled,
} from "@element-plus/icons-vue"

import { DateFormatTypes, dateForm } from "@/core/helpers/dateformat"

import { StudentDetailDto } from "@/core/models/StudentDetailDto"
import contact from "@/components/education/student/contact.vue"
import general from "@/components/education/student/general.vue"
import parent from "@/components/education/student/parent.vue"
import sale from "@/components/education/student/sale.vue"
import note from "@/components/education/student/note.vue"
import studentNotes from "@/components/education/student/student-notes.vue"
import { useRoute, useRouter } from "vue-router"
import { staticGenderList, staticNoteLevelList } from "@/core/enums/enums"
import { ElMessage, FormInstance, UploadProps } from "element-plus"
import JwtService from "@/core/services/JwtService"
import { useStore } from "vuex"
import { Actions } from "@/store/enums/StoreEnums"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { UpdatePersonDto } from "@/core/models/UpdatePersonDto"

export default defineComponent({
  name: "student-summary",
  components: {
    contact,
    general,
    parent,
    sale,
    note,
    //    ArrowLeft,
    studentNotes,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const appUrl = process.env.VUE_APP_URL ?? ""
    const isLoading = ref(false)
    const studentData = ref<StudentDetailDto>({ isForeign: true } as StudentDetailDto)
    const validatePersonData = ref<UpdatePersonDto>({} as UpdatePersonDto)
    const activeName = ref("student-general")
    const validateModalVisible = ref(false)
    const ruleFormRef = ref<FormInstance>()

    const localVisible = ref(null)

    onMounted(async () => {
      if (!route.params.student_id) router.push("dashboard-summary")
      if (route.meta.studentTab) {
        activeName.value = route.meta.studentTab as string
      }
      await getStudent(route.params.student_id)
    })

    watch(
      () => route.params.student_id,
      async newValue => {
        if (newValue) {
          await getStudent(newValue)
        }
      }
    )

    const getStudent = async studentId => {
      await getSummary(studentId)
      await getNotes(studentId)
      await getSales(studentId)
      await getParents(studentId)
    }

    const getSummary = async studentId => {
      isLoading.value = true
      const { data: studentdata } = await ApiService.get("student/" + studentId + "/summary")
      studentData.value = {
        ...studentdata,
        profilePictureUrl: appUrl + "/" + studentdata.profilePictureUrl,
      }
      isLoading.value = false
    }

    const getNotes = async studentId => {
      await store.dispatch(Actions.FETCH_STUDENT_NOTES, studentId)
    }

    const getSales = async studentId => {
      await store.dispatch(Actions.FETCH_STUDENT_ORDERS, studentId)
    }

    const getParents = async studentId => {
      await store.dispatch(Actions.FETCH_STUDENT_PARENTS, studentId)
    }

    const pinnedNoteList = computed(() => {
      return store.getters.getStudentPinnedNoteList
    })

    const visiblePoppedNoteList = ref(true)

    const showPoppedNoteModal = computed(() => {
      return poppedNoteList.value.length > 0
    })

    const showPinNoteModal = computed(() => {
      return pinnedNoteList.value.length > 0
    })

    const poppedNoteList = computed(() => {
      return store.getters.getStudentPopupNoteList
    })

    const fullName = computed(() => {
      return (
        studentData.value.name?.toLocaleUpperCase("tr-TR") +
        " " +
        studentData.value.surname?.toLocaleUpperCase("tr-TR")
      )
    })

    const updateStudentSummary = async () => {
      const data = {
        id: route.params.student_id,
        gender: studentData.value.gender,
        profilePicture: studentData.value.profilePictureId,
      }
      await ApiService.post("student/summary/edit", data)
      await getSummary(route.params.student_id)
    }

    const handleProfilePictureSuccess: UploadProps["onSuccess"] = async (response, uploadFile) => {
      studentData.value = {
        ...studentData.value,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profilePictureUrl: URL.createObjectURL(uploadFile.raw!),
        profilePictureId: response[0],
      }
      await updateStudentSummary()
    }

    const beforeProfilePictureUpload: UploadProps["beforeUpload"] = rawFile => {
      if (rawFile.type !== "image/jpeg" && rawFile.type !== "image/png") {
        ElMessage.error("Profil resmi sadece JPG ve PNG formatında olabilir")
        return false
      } else if (rawFile.size / 1024 / 1024 > 2) {
        ElMessage.error("2MB'dan fazla olamaz")
        return false
      }
      return true
    }

    const tabChange = panelName => {
      router.push({ name: panelName })
    }

    const authHeader = {
      Authorization: `Bearer ${JwtService.getToken()}`,
    }

    const goTo = studentId => {
      if (studentId)
        router.push({
          name: "student-summary",
          params: { student_id: studentId },
        })
      else ElMessage.error("Öğrenci Bulunmuyor")
    }

    const summaryContact = ref("")

    const setActiveAndEdit = (activeTab, editValue) => {
      activeName.value = activeTab
      summaryContact.value = editValue
    }

    const editEmail = () => {
      setActiveAndEdit("student-contact", "email")
    }

    const editPhone = () => {
      setActiveAndEdit("student-contact", "phone")
    }

    const editAddress = () => {
      setActiveAndEdit("student-contact", "address")
    }

    // validate modal

    const validatePerson = formEl => {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          validatePersonData.value.personId = studentData.value.personId
          await ApiService.post("person/update", validatePersonData.value)
          validateModalVisible.value = false
          getSummary(studentData.value.id)
        }
      })
    }

    const handleValidateModalOpen = () => {
      validateModalVisible.value = true
      validatePersonData.value = {} as UpdatePersonDto
    }

    const validateRules = ref({
      citizenId: getRule(RuleTypes.TCID, "T.C. No"),
      name: getRule(RuleTypes.TEXT100, "Ad"),
      surname: getRule(RuleTypes.TEXT100, "Soyad"),
      birthDate: getRule(RuleTypes.DATE, "Doğum"),
    })

    return {
      dateForm,
      DateFormatTypes,
      ArrowRight,
      ArrowLeft,
      Minus,
      Edit,
      activeName,
      studentData: studentData,
      tabChange,
      staticGenderList,
      staticNoteLevelList,
      handleProfilePictureSuccess,
      beforeProfilePictureUpload,
      authHeader,
      updateStudentSummary,
      goTo,
      appUrl,
      pinnedNoteList,
      poppedNoteList,
      visiblePoppedNoteList,
      showPoppedNoteModal,
      showPinNoteModal,
      fullName,
      isLoading,
      editEmail,
      editPhone,
      editAddress,
      route,
      summaryContact,
      getSummary,
      validatePersonData,
      validateModalVisible,
      validateRules,
      CircleCheckFilled,
      validatePerson,
      ruleFormRef,
      localVisible,
      handleValidateModalOpen,
      WarnTriangleFilled,
    }
  },
})
