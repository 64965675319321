
import { computed, defineComponent, onMounted, ref } from "vue"

import { Delete, Edit, Plus, Check, Close } from "@element-plus/icons-vue"
import { StudentParent } from "@/core/models/StudentParent"
import { staticRelationShipList } from "@/core/enums/enums"
import ApiService from "@/core/services/ApiService"
import { useRoute, useRouter } from "vue-router"
import { FormInstance, FormItemRule } from "element-plus"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { InternalRuleItem } from "async-validator"
import swalConfirm from "@/core/helpers/swal-confirm"
import { useStore } from "vuex"
import { Actions } from "../../../store/enums/StoreEnums"
import { SelectModel } from "../../../core/models/SelectModel"
import { Arrayable } from "element-plus/es/utils"
export default defineComponent({
  name: "student-parent",
  inheritAttrs: false,
  props: ["studentId"],
  setup(props) {
    const store = useStore()
    const isDrawerOpen = ref(false)

    const router = useRouter()
    const route = useRoute()
    const editMode = ref<string | undefined>()
    const ruleFormRef = ref<FormInstance>()
    const relationShip = ref<number>()
    const occupationId = ref()
    const addLoading = ref(false)
    const parentData = ref<StudentParent>({} as StudentParent)
    const occupationList = ref<SelectModel[]>([])
    const parents = computed(() => {
      return store.getters.getStudentParentList
    })

    const getParents = async studentId => {
      await store.dispatch(Actions.FETCH_STUDENT_PARENTS, studentId)
    }

    const getOccupationList = async () => {
      const { data } = await ApiService.get("occupation")
      occupationList.value = data
    }

    onMounted(async () => {
      if (!route.params.student_id) router.push("dashboard-summary")

      await getOccupationList()
    })

    const handleDelete = async parentId => {
      const confirmResult = await swalConfirm()
      if (confirmResult.isConfirmed) {
        await ApiService.post("person/parent/remove/" + parentId, {})
        await getParents(props.studentId)
      }
    }

    const handleEdit = (id, _relationShip, _occupationId) => {
      editMode.value = id
      relationShip.value = _relationShip
      occupationId.value = _occupationId
    }

    const editParent = async (id, _relationShip, _occupationId) => {
      await ApiService.post("student/parent/edit", {
        id,
        relationShip: _relationShip,
        occupationId: _occupationId,
      })
      await getParents(props.studentId)
      editMode.value = undefined
    }

    const addParent = formEl => {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          addLoading.value = true
          await ApiService.post("person/parent/add", {
            ...parentData.value,
            studentId: props.studentId,
          })
          addLoading.value = false
          await getParents(props.studentId)
          isDrawerOpen.value = false
          parentData.value = {} as StudentParent
        }
      })
    }

    const handleAdd = () => {
      isDrawerOpen.value = !isDrawerOpen.value
    }

    // TODO: validateTCID şimdilik rule dan kopyalandı düzeltilecek
    const validateTCID = (
      rule: InternalRuleItem,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      callback: (error?: string | Error | undefined) => void
    ) => {
      if (parentData.value.isFabricated) {
        callback()
      }

      if (value === "") {
        callback(new Error("Lütfen T.C Kimlik Numarası Giriniz!"))
      } else if (isNaN(value)) {
        callback(new Error("T.C Kimlik Numarası Rakam Olmalıdır"))
      }
      value = value + ""
      if (value[0] == "0") {
        callback(new Error("T.C Kimlik Numarası 0 İle Başlayamaz!"))
      } else if (value.toString().length != 11) {
        callback(new Error("T.C Kimlik Numarası 11 Karakter Olmalıdır"))
      } else {
        let tek = 0,
          cift = 0,
          sonuc = 0,
          TCToplam = 0

        tek =
          parseInt(value[0]) +
          parseInt(value[2]) +
          parseInt(value[4]) +
          parseInt(value[6]) +
          parseInt(value[8])
        cift = parseInt(value[1]) + parseInt(value[3]) + parseInt(value[5]) + parseInt(value[7])

        tek = tek * 7
        sonuc = Math.abs(tek - cift)
        if (sonuc % 10 != value[9]) {
          callback(new Error("T.C Kimlik Numarası Geçersiz!"))
        }

        for (let ix = 0; ix < 10; ix++) {
          TCToplam += parseInt(value[ix])
        }

        if (TCToplam % 10 != value[10]) {
          callback(new Error("T.C Kimlik Numarası Geçersiz!"))
        }

        callback()
      }
    }

    const rules = ref({
      citizenId: [
        {
          validator: validateTCID,
          trigger: "blur",
        },
      ] as Arrayable<FormItemRule>,
      name: getRule(RuleTypes.TEXT100, "Ad"),
      surname: getRule(RuleTypes.TEXT100, "Soyad"),
      birthDate: getRule(RuleTypes.DATE, "Doğum"),
      relationShip: getRule(RuleTypes.SELECT, "Yakınlık"),
    })

    const cancelEdit = () => {
      editMode.value = undefined
      relationShip.value = undefined
    }

    return {
      parents,
      Delete,
      Edit,
      Check,
      Close,
      Plus,
      handleDelete,
      handleEdit,
      isDrawerOpen,
      handleAdd,
      addParent,
      addLoading,
      rules,
      ruleFormRef,
      parentData,
      staticRelationShipList,
      editMode,
      editParent,
      relationShip,
      cancelEdit,
      occupationList,
      occupationId,
    }
  },
})
