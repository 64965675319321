
import { computed, defineComponent, ref } from "vue"
import { Delete, Edit, Plus, Star, StarFilled } from "@element-plus/icons-vue"
import { staticNoteLevelList } from "@/core/enums/enums"
import { StudentNote } from "@/core/models/StudentNote"
import ApiService from "@/core/services/ApiService"

import swalConfirm from "@/core/helpers/swal-confirm"
import { dateForm, DateFormatTypes } from "@/core/helpers/dateformat"
import { useStore } from "vuex"
import { Actions } from "@/store/enums/StoreEnums"

export default defineComponent({
  name: "student-note",
  inheritAttrs: false,
  components: {},
  props: ["studentId"],
  setup(props) {
    const store = useStore()
    const isDrawerOpen = ref(false)
    const note = ref<StudentNote>({} as StudentNote)

    const notes = computed(() => {
      return store.getters.getStudentNoteList
    })

    const isNotesLoading = ref(false)

    const getNotes = async studentId => {
      isNotesLoading.value = true
      await store.dispatch(Actions.FETCH_STUDENT_NOTES, studentId)
      isNotesLoading.value = false
    }

    async function handleDelete(noteId) {
      const confirmResult = await swalConfirm()
      if (confirmResult.isConfirmed) {
        await ApiService.get("student-note/remove/" + noteId)
        await getNotes(props.studentId)
      }
    }

    const openDrawer = async (noteId?: string) => {
      isDrawerOpen.value = !isDrawerOpen.value
      if (noteId) await getNote(noteId)
      else
        note.value = {
          isPinned: false,
          isPopup: false,
        } as StudentNote
    }

    const getNote = async (noteId: string) => {
      const { data } = await ApiService.get("student-note/" + noteId)
      note.value = data
    }

    const addOrUpdate = async () => {
      if (note.value.id) {
        const data = { ...note.value }
        await ApiService.post("student-note/edit", data)
      } else {
        const data = { ...note.value, studentId: props.studentId }
        await ApiService.post("student-note/add", data)
      }
      isDrawerOpen.value = false
      await getNotes(props.studentId)
    }

    return {
      notes,
      Delete,
      Edit,
      Plus,
      Star,
      StarFilled,
      handleDelete,
      isDrawerOpen,
      note,
      openDrawer,
      staticNoteLevelList,
      addOrUpdate,
      isNotesLoading,
      dateForm,
      DateFormatTypes,
    }
  },
})
